export default {
    init() {
        // JavaScript to be fired on all pages

        function resetDefaultTabindex() {
            $('.nav-primary .nav > li > a').attr('tabindex', '-1')
            $('.nav-primary .nav > li > ul > li > a').attr('tabindex', '-1')
            $('.banner__slide').attr('tabindex', '-1')
        }

        if ($(window).width() >= 991) {

            setTimeout(function () {
                if(!$('.cookie').hasClass('cookie--slide')) {
                    $('.cookie a, .cookie button').attr('tabindex', '-1');
                }
            }, 1000)

            $('.js-avg-simple-ok').click(function () {
                $('.cookie a, .cookie button').attr('tabindex', '-1');
            })

            $('.nav-primary .nav > li').on('mouseenter', function () {
                if (!$(this).hasClass('open')) {
                    $('.nav-primary .nav > li').removeClass('open');
                    $(this).find('a').first().focus();
                    $(this).addClass('open');
                }
            })

            $('.nav-primary .nav > li').on('mouseleave', function () {
                $(this).removeClass('open');
            })

            $('.top__languagues__inner a:first-child').attr('tabindex', '5')

            $('.cookie--off .btn').attr('tabindex', '-1')
            $('.cookie--off a').attr('tabindex', '-1')

            resetDefaultTabindex();

            $('.menu__icon').click(function () {
                if (!$('body').hasClass('menu__open')) {
                    $('.nav-primary .nav > li > a').attr('tabindex', '-1')
                    $('.nav-primary .nav > li > ul > li > a').attr('tabindex', '-1')
                } else {
                    $('.nav-primary .nav > li > a').attr('tabindex', '0');
                    $('.nav-primary .nav > li > ul > li > a').attr('tabindex', '0')

                    $('.nav-primary .nav > li:first-child').addClass('open');
                    $('.nav-primary .nav > li:first-child > a').focus();
                }
            })

            $('.nav-primary .nav > li > a').focus(function () {
                $('.nav-primary .nav > li').removeClass('open');
                $(this).parent().addClass('open');
            })

            $('.nav-primary .nav > li > ul > li > a').focus(function () {
                let $parent = $(this).parent().parent().parent();
                if (!$parent.hasClass('open')) {
                    $('.nav-primary .nav > li').removeClass('open');
                    $parent.addClass('open');
                }
            })

            $('.nav-primary .nav > li:last-child > ul > li:last-child > a').on('keydown blur', function (e) {
                if (!e.shiftKey && e.keyCode === 9) {
                    $('.menu__icon').removeClass('open');
                    $('.nav-primary').removeClass('menu__open');
                    $('body').removeClass('menu__open');
                    resetDefaultTabindex();
                }
            });

            $('.menu__icon').on('keydown blur', function (e) {
                if ($('body').hasClass('menu__open')) {
                    if (e.shiftKey && e.keyCode === 9) {
                        $('.menu__icon').removeClass('open');
                        $('.nav-primary').removeClass('menu__open');
                        $('body').removeClass('menu__open');
                    }
                }
            });
        }

        // Mobile only
        $('.tickets__button__wrap a').attr('tabindex', '-1')

        $('.sf-input-checkbox').focus(function () {
            $('.sf-level-0').removeClass('focus')
            $(this).parent().addClass('focus')
        });

        $(document).on('sf:ajaxfinish', '.searchandfilter', function(){
            $('.sf-input-checkbox').focus(function () {
                $('.sf-level-0').removeClass('focus')
                $(this).parent().addClass('focus')
            });
        });

        // // Jquery-match-height
        // $(function() {
        //     $('.example').matchHeight();
        // });

        // $(document).ready(function() {
        //     $(".fancybox").fancybox();
        // });

        if (!($.cookie('hide_popup_' + $('.language__item--active').html()))) {
            $('.popup__content').fancybox().trigger('click');
        }

        $('.popup__content .fancybox-close-small').click(function () {
            $.cookie('hide_popup_' + $('.language__item--active').html(), true, {expires: 365, path: '/'});
        });

        $('.fancybox-slide--current').click(function () {
            $.cookie('hide_popup_' + $('.language__item--active').html(), true, {expires: 365, path: '/'});
        });

        $('.activities__slider').slick({
            dots: true,
            arrows: true,
            prevArrow: $('.activities__slider__arrow--prev'),
            nextArrow: $('.activities__slider__arrow--next'),
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            fade: true,
        });

        /* eslint-disable */

        let ua = window.navigator.userAgent;
        let msie = ua.indexOf('MSIE ');
        let var_width = true;
        let center_mode = true;
        let center_padding = '140px';

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {  // If Internet Explorer, return version number
            var_width = false;
            center_mode = false;
            center_padding = '0px';
        }

        /* eslint-enable */
        $('.kunstwerken__slider').slick({
            centerMode: true,
            centerPadding: center_padding,
            infinite: true,
            variableWidth: var_width,
            prevArrow: $('.archive__kunstwerken__arrow--prev'),
            nextArrow: $('.archive__kunstwerken__arrow--next'),
            slidesToShow: 1,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        centerMode: false,
                        variableWidth: false,
                    },
                },
                {
                    breakpoint: 767,
                    settings: {
                        centerMode: false,
                        variableWidth: false,
                        prevArrow: $('.prev-wrap'),
                        nextArrow: $('.next-wrap'),
                    },
                },
            ],
        });

        /* eslint-disable */
        // eventim.iframe();
        /* eslint-enable */

        $(window).on('load resize', function () {
            // if ($(window).width() < 991) {
            //     var $height = $('.front__layer__img').height();
            //
            //     $('.activities__slider__arrow').css('top', $height / 2 - 20);
            // } else {
            //     $('.activities__slider__arrow').css('top', 'calc(50% - 20px)');
            // }

            if ($(window).width() < 768) {
                $(window).scroll(function () {
                    var sticky = $('.menu__icon'),
                        scroll = $(window).scrollTop();

                    if (scroll >= 45) {
                        sticky.addClass('menu--fixed');
                    } else {
                        sticky.removeClass('menu--fixed');
                    }
                });
            }
        });

        // Jquery-match-height
        $(function () {
            $('.match__column').matchHeight();
            $('.visit__block__inner').matchHeight();
            $('.arrangement__item__content').matchHeight();
            // $('.news__block').matchHeight();
        });

        $(document).on('sf:ajaxfinish', '.searchandfilter', function () {
            // $('.news__block').matchHeight();
        });

        $('.slick-slider').css('opacity', '1');

        let $container = $('.row--tentoonstellingen');
        $container.infiniteScroll({
            // options
            path: '.navigation .next',
            append: '.archive__item--passed ',
            history: false,
            hideNav: '.navigation',
            scrollThreshold: false,
            button: '.btn-loadmore',
        });

        $container.on( 'append.infiniteScroll', function( ) {
            $('.navigation').next().find('a').focus();
        });

        const $grid = $('.grid');

        $grid.masonry({
            itemSelector: '.grid-item',
            gutter: 0,
        });

        $grid.imagesLoaded(function () {

            $grid.find('img').each(function () {
                $(this).load(function () {
                    $grid.masonry('reloadItems');
                    $grid.masonry('layout');
                });
            });
        });

        $(document).on('sf:ajaxfinish', '.searchandfilter', function () {
            $grid.find('img').each(function () {
                $(this).load(function () {
                    $grid.masonry('reloadItems');
                    $grid.masonry('layout');
                });
            });
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
