$(document).ready(function () {

    // Variable
    var primaryMenu = $('.nav-primary .nav');
    var menuIcon = $('.menu__icon')
    var primaryOverlayTouch = $('.nav-primary__overlay');
    var touchEvents = $('.touchevents');
    var body = $('body');

    var primaryMenuWrapper = $('.nav-primary');

    // Variable: primaryMenu
    // Add icon angle down to element with dropdown menu
    primaryMenu.find('> li.menu-item-has-children > a').append('<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>');
    primaryMenu.find('> li ul.sub-menu > li.menu-item-has-children > a').append('<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-right" class="test1 svg-inline--fa fa-angle-right fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z"></path></svg>');

    // Add class sub-menu1, sub-menu2 and submenu-3 to first, second and third dropdown menu
    primaryMenu.find('> li > ul.sub-menu').addClass('sub-menu1');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu').addClass('sub-menu2');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu li > ul.sub-menu').addClass('sub-menu3');

    // Variable: touchEvents
    touchEvents.find('.nav-primary .nav .menu-item-has-children').append('<div class="menu-item-has-children__overlay"></div>');
    touchEvents.find('.menu-item-has-children__overlay').on('click', function () {
        $(this).parent('.menu-item-has-children').addClass('menu-item-has-children__open');
    });

    // Variable: primaryOverlayTouch
    primaryOverlayTouch.on('click', function () {
        menuIcon.removeClass('open');
        body.removeClass('menu__open');
    });

    // Variable: menuIcon
    menuIcon.on('click', function () {
        $(this).toggleClass('open');
        body.toggleClass('menu__open');
        primaryMenuWrapper.toggleClass('menu__open');
    });

// Custom

    if ($(window).width() <= 991) {
        $('.menu-item-has-children').on('click', function () {
             // var $subMenu = $(this).find('.sub-menu');
            $(this).toggleClass('submenu__open open');
        });
    }
});
